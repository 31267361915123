import { useRouter } from "next/router";
import PrimaryButton from "../components/buttons/primary-button";
import SafeContainer from "../components/navigation/safe-container";
import Image from "next/image";

const Custom404 = () => {
  const router = useRouter();
  return (
    <SafeContainer navbar>
      <main className="flex flex-col bg-background overflow-x-clip min-h-[100vh] justify-center pb-6 px-4">
        <div className="flex flex-col items-center space-y-20">
          <div className="text-center space-y-2">
            <h1 className="leading-tight">Oops!</h1>
            <h2 className="text-lg md:text-3xl  text-subtitle font-medium">
              This page does not exist!
            </h2>
          </div>

          <div className="mt-3">
            <Image
              src="/assets/svg/404.svg"
              alt="404"
              width={795}
              height={338}
            />
          </div>

          <PrimaryButton
            text="Go Home"
            onClick={() => router.push("/")}
            className="[&>*>p]:font-bold"
          />
        </div>
      </main>
    </SafeContainer>
  );
};

export default Custom404;
