import { cx } from "@/lib/utils/className.utils";
import Footer from "./footer";

interface SafeContainerProps {
  children: React.ReactNode;
  footer?: boolean;
  navbar?: boolean;
  showAudioPlayer?: boolean;
  className?: string;
}
const SafeContainer = ({
  children,
  footer,
  showAudioPlayer = true,
  className,
}: SafeContainerProps) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className={cx("max-w-safe mx-auto w-full relative grow", className)}>
        {children}
      </div>
      {footer && <Footer safe />}
    </div>
  );
};

export default SafeContainer;
